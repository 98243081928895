<template>
  <div class="page">
    <el-form inline label-position="left">
      <el-form-item label="客户：" label-width="60px">
        <el-input></el-input>
      </el-form-item>
      <el-form-item label="单据日期：" label-width="90px">
        <el-date-picker />
      </el-form-item>
      <el-form-item label="交货日期：" label-width="90px">
        <el-date-picker />
      </el-form-item>
      <el-form-item>
        <el-radio-group>
          <el-radio :label="1">订货</el-radio>
          <el-radio :label="0">退货</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="单据编号：" label-width="90px">
        <el-input readonly />
      </el-form-item>
    </el-form>
    <el-table border>
      <el-table-column
        header-align="center"
        align="center"
        type="index"
        width="50"
      ></el-table-column>
      <el-table-column header-align="center" align="center" width="80">
        <template slot-scope="scope">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-around;
            "
          >
            <i class="el-icon-plus" style="cursor: pointer"></i>
            <i class="el-icon-delete" style="cursor: pointer"></i>
            <i class="el-icon-shopping-cart-1" style="cursor: pointer"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="商品">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="单位">
        <template slot-scope="scope">
          <el-input readonly></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="仓库">
        <template slot-scope="scope">
          <el-select></el-select>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="数量">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="销售单价">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="折扣率（%）">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="折扣额">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="购货金额">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>

      <el-table-column header-align="center" align="center" label="备注">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
    </el-table>
    <el-form style="margin-top: 25px" label-position="left" label-width="100px">
      <el-row :gutter="10">
        <el-col>
          <el-form-item label-width="0">
            <el-input
              type="textarea"
              :row="6"
              placeholder="暂无备注信息"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item label="优惠率:">
            <el-input><span slot="suffix">%</span></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="优惠金额:">
            <el-input></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="优惠后金额:">
            <el-input></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col>
          <el-form-item label="制单人:">
            <span>name</span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "customerOrder",
};
</script>

<style scoped>
.page {
  background-color: #fff;
  padding: 20px;
}
</style>
